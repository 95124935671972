import React, { useState, useCallback, useEffect } from "react";

function AnimatedIntro() {
  const frontendPrinciple = [
    'Responsive', 'Mobile First', 'Simple & Beautiful','Interactive', 'Cloud Based','PWA']
  const [newName, setnewName] = useState("");

  const colorName =['#42c58a','#c71337','#4ec7f3', '#E91E63','#9C27B0','#00BCD4','#3F51B5'];
  const [newColor, setNewColor]= useState("");

  const shuffle = useCallback(() => {
      const index = Math.floor(Math.random() * frontendPrinciple.length);
      setnewName(frontendPrinciple[index]);
      const index_color = Math.floor(Math.random() * colorName.length);
      setNewColor(colorName[index_color]);
  }, []);

  useEffect(() => {
      const intervalID = setInterval(shuffle, 1000);
      return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <article className="brief">
      <div id="name">
        <h1>
        <span>Hi </span> <span> I'm Suraj.</span>

        </h1>
      </div>
    <div>
<h2>

        <span className="intro">Front End Developer</span>
</h2>
<h3>

      <span className="frontend" style={{color:`${newColor||colorName[3]}` }}>{newName ||frontendPrinciple[0]}</span>
</h3>
     

    
      <h4>

    <p>I design & code. I love doing it.</p>
       <p>Based in Sydney Australia</p>
      </h4>
    </div>
    </article>
  )
}

export default AnimatedIntro

import React from "react"
import Layout from "../components/Layout"
import { CgMail } from "react-icons/cg"
import { FaLinkedin, FaInstagramSquare } from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import RecipesList from "../components/RecipesList"
import SEO from "../components/SEO"
import WeatherHome from "../components/WeatherHome"
import AnimatedIntro from "../components/AnimatedIntro"
// import ExampleDoc from "../assets/myDoc.pdf"

const About = () => {
  const currentSkill=["HTML", "CSS", "SCSS", "JavaScript","TypeScript", "REST APIs", "ReactJS", "Git",
    "Jira", "Headless CMS", "GraphQL", "GatsbyJS", "Java", "MYSQL"];
    const newSkill=["MERN(Mongo, Express, React, Node)", "NextJS"," Other JS Frameworks",
    "Responsive Designs"]
  return (
    <Layout>
      <SEO
        title="Portfolio"
        description="maatocollection Web Application, Singel Page Application, Website, Mobile First , Responsive Web, Software Engineer, React Developer, Javascript Developer, Frontend Developer, HTML, CSS, SASS, JavaScript, REST APIs, ReactJS, TypeScript, Git,
        Jira, Headless CMS, GraphQL, GatsbyJS, Java, MYSQL"
      />
      <main className="page">
        <section className="about-page">
          <article>
            <WeatherHome />
            <AnimatedIntro />

            {/* <p>
            I have a special interest in modern web applications.
            </p>
            <p>
              I am a Software Developer.
              I can work as an active member of the development team, specialising in Front End
              Development, can work on projects using new technologies or
              sometimes unfamiliar.
            </p> */}
          </article>
          <div>
            <StaticImage
              src="../assets/images/about.jpg"
              alt="Software Developer"
              className="about-img"
              placeholder="tracedSVG"
            />
            <div>
              <div className="connection">
                <Link to="/contact">
                  <button className="btn">Say Hello</button>
                </Link>
                <div>
                  <Link to="https://www.linkedin.com/in/mrsurajpokhrel/"    target="_blank"
          rel="noopener noreferrer">
                    <FaLinkedin style={{ color: "$B23121" }} alt="LinkedIn"/>
                  </Link>

                  <Link to="https://www.instagram.com/pok.suraj/"    target="_blank"
          rel="noopener noreferrer">
                    <FaInstagramSquare style={{ color: "#B23121" }} alt="Instagram" />
                  </Link>
                  <Link href="mailto:surajpok.au@gmail.com">
                    <CgMail style={{ color: "#B23121" }} alt="Gmail"/>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <a href={ExampleDoc} download="CV_Suraj_Developer" target="_blank">
            <FcDocument size={70}/>My Resume
          </a> */}
        </section>
        <article>
        <div className="single-instruction">
            <header>
              <h4>Education</h4>
              <div></div>
            </header>
            <h5>
              MIT Software Design and Development, Charles Sturt University,
              Australia
              <br />
            </h5>
            <h5>
              Bachelor’s Degree in Computer Engineering, Tribhuvan University,
              Nepal
              <br />
            </h5>
          </div>
          <div className="single-instruction">
            <header>
              <h4>Technologies I am Using</h4>
              <div></div>
            </header>
            {
              currentSkill.map(item=>{
                return <span className="tech-tags">{item}</span>
              })
            }
              {/* <span className="tech-tags">HTML</span> */}
            {/* <code>
              HTML, CSS, SCSS, JavaScript,TypeScript, REST APIs, ReactJS, Git,
              Jira, Headless CMS, GraphQL, GatsbyJS, Java, MYSQL,
            </code> */}
          </div>
          <div className="single-instruction">
            <header>
              <h4>Technologies I am Learning and Want to work on</h4>
              <div></div>
            </header>
            {
              newSkill.map(item=>{
                return <span className="tech-tags">{item}</span>
              })
            }
            {/* <code>
              MERN(Mongo, Express, React, Node), NextJS, other JS Frameworks,
              Newer Responsive Design.
            </code> */}
          </div>
          <div className="single-instruction">
            <header>
              <h4>Hobby and Passion</h4>
              <div></div>
            </header>
            <p>
              I consistently set firm goals for myself and take the necessary
              steps to achieve those milestones.{" "}
              <p>I believe never give up is a key to success.</p>
            </p>
            <p>
              Talking about experiences and hobbies, I started playing tennis
              when I was 12 years. I used to spend most of my spare time in
              training and used to watch other people playing on TV and at the
              stadium. I got many friends while playing tennis and we are still
              connected. That didn't make me a professional player, but it
              energizes me and focuses on my work. Even today I can't say no
              when someone invites me to this sport.
            </p>
            <p>
              I have recently learned to ride on a skateboard, it’s something
              that I have learned late with a great passion. I am enjoying it
              and training whenever I am free. I try to keep myself safe and
              follow the guidelines. A few of my friends are inspired by my new
              passion, they have also started, and I am helping them as well.
            </p>
            <p>
              Also, I like to go hiking, content writing and it’s been fun to
              use my experiences and skills in blog writing and making videos. I
              am open to feedback, learn new stuff and try it, and keeping
              myself busy.
            </p>
            <p>
              I work well both independently and as part of a team. If you want
              to know more? I'd love to talk to you.
            </p>
          </div>
          <div className="single-instruction">
            <header>
              <h4>Achievements</h4>
              <div></div>
            </header>
            <ul>
              <li>Empolyee of the Month and Team Player awards.</li>
              <li>
                Honoured as <strong>Dean Award</strong> ,{" "}
                <strong>Academic Award</strong> and{" "}
                <strong>Research Award</strong> from Charles Sturt University,
                Australia.
              </li>
              <li>
                Published the{" "}
                <Link to="https://onlinelibrary.wiley.com/doi/10.1002/rcs.1958"    target="_blank"
          rel="noopener noreferrer">
                  Research Paper
                </Link>{" "}
                in the International Journal of Medical Robotics and Computer
                Assisted Surgery.
              </li>
            </ul>
          </div>
        </article>

        <div className="single-instruction">
          <header>
            <h4>Look at my Awesomeme Projects</h4>
            <div></div>
          </header>

          <section className="project-list">
            <div>
              <h5>Fetebird</h5>
              <Link to="https://www.fetebird.com"    target="_blank"
          rel="noopener noreferrer">
                <StaticImage
                  src="../assets/images/fetebird.png"
                  alt="Fetebird"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                  className="project-img"
                
                />
                <br />
                <p>Work on progress.. LIVE</p>
              </Link>
            </div>

            <div>
              <h5>GitHub</h5>
              <Link to="https://github.com/Shuraz"    target="_blank"
          rel="noopener noreferrer">
                <StaticImage
                  src="../assets/images/Octocat.png"
                  alt="GitHub Suraj"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                />
                <br />
                <p>Link to my GitHub</p>
              </Link>
            </div>
            {/* <RecipesList recipes={recipes} /> */}
          </section>
        </div>
      </main>
    </Layout>
  )
}

// export const query = graphql`
//   {
//     allContentfulRecipe(
//       sort: { fields: title, order: ASC }
//       filter: { featured: { eq: true } }
//     ) {
//       nodes {
//         id
//         title
//         cookTime
//         prepTime
//         image {
//           gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
//         }
//       }
//     }
//   }
// `

export default About
